// import React, { useEffect } from "react";
// import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
// import PublicRoute from "./PublicRoute";
// import LandingPage from "pages/website/LandingPage";
// import AboutPage from "pages/website/AboutPage";
// import FaqsPage from "pages/website/FaqsPage";
// import PricingPage from "pages/website/PricingPage";
// import ErrorPage from "pages/ErrorPage/errorpage";
// import {
//   CookiePolicy,
//   PrivacyPolicy,
//   TermsConditions
// } from "pages/website/Legal";
// import { LearnPage, LearnDetailsPage } from "pages/website/Learn";
// import ScrollToTop from "./ScrollToTop";
// import ProgramsPage from "pages/website/Programs";
// import { gaInitialize, gaRecordPageView } from "utils/analytics";

// export default function Routes() {
//   const history = useHistory();

//   useEffect(() => {
//     gaInitialize();
//     gaRecordPageView(history.location);
//   }, []);

//   return (
//     <>
//       <ScrollToTop />
//       <Switch>
//         {/* Website Routes */}
//         <PublicRoute component={ProgramsPage} path="/programs" />
//         <PublicRoute component={LearnDetailsPage} path="/learn/:articleId" />
//         <PublicRoute component={LearnPage} path="/learn" />
//         <PublicRoute component={CookiePolicy} path="/cookie-policy" />
//         <PublicRoute component={PrivacyPolicy} path="/privacy" />
//         <PublicRoute component={TermsConditions} path="/terms" />
//         <PublicRoute component={PricingPage} path="/membership" />
//         <PublicRoute component={FaqsPage} path="/faqs" />
//         <PublicRoute component={AboutPage} path="/about" />
//         <PublicRoute component={LandingPage} exact path="/" />
//         <PublicRoute component={ErrorPage} path="/error" />
//       </Switch>
//     </>
//   );
// }

import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, useHistory } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import ScrollToTop from "./ScrollToTop";
import { gaInitialize, gaRecordPageView } from "utils/analytics";
import { PageLoader } from "components/PageLoader";

// Lazy load the route components
const LandingPage = lazy(() => import("pages/website/LandingPage"));
const AboutPage = lazy(() => import("pages/website/AboutPage"));
const FaqsPage = lazy(() => import("pages/website/FaqsPage"));
const PricingPage = lazy(() => import("pages/website/PricingPage"));
const ErrorPage = lazy(() => import("pages/ErrorPage/errorpage"));
const CookiePolicy = lazy(() =>
  import("../pages/website/Legal/components/CookiePolicy")
);
const PrivacyPolicy = lazy(() =>
  import("../pages/website/Legal/components/PrivacyPolicy")
);
const TermsConditions = lazy(() =>
  import("../pages/website/Legal/components/TermsConditions")
);
const LearnPage = lazy(() => import("../pages/website/Learn"));
const LearnDetailsPage = lazy(() =>
  import("../pages/website/Learn/LearnDetailsPage")
);
const ProgramsPage = lazy(() => import("pages/website/Programs"));
const Download = lazy(() => import("pages/website/Download"));
const Verve = lazy(() => import("pages/website/Legal/components/Verve"));

export default function Routes() {
  const history = useHistory();

  useEffect(() => {
    gaInitialize();
    gaRecordPageView(history.location);
  }, []);

  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {/* Website Routes */}
          <PublicRoute component={ProgramsPage} path="/programs" />
          <PublicRoute component={LearnDetailsPage} path="/learn/:articleId" />
          <PublicRoute component={LearnPage} path="/learn" />
          <PublicRoute component={CookiePolicy} path="/cookie-policy" />
          <PublicRoute component={PrivacyPolicy} path="/privacy" />
          <PublicRoute component={TermsConditions} path="/terms" />
          <PublicRoute component={PricingPage} path="/membership" />
          <PublicRoute component={FaqsPage} path="/faqs" />
          <PublicRoute component={AboutPage} path="/about" />
          <PublicRoute component={LandingPage} exact path="/" />
          <PublicRoute component={ErrorPage} path="/error" />
          <PublicRoute component={Download} path="/download" />
          <PublicRoute component={Verve} path="/verve" />
        </Switch>
      </Suspense>
    </>
  );
}
