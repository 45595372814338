import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import validator from "validator";
import WrappedIcon from "components/WrappedIcon";
import "./styles.css";
import "./responsive.css";
import Icon from "components/Icon";
import Button from "components/Button";
import { useCookies } from "react-cookie";
import axios from "axios";
import { capitalizeFirstLetter } from "utils/format";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import modalPattern from "assets/images/modal-pattern.svg";
import modalImage1 from "assets/images/modal-illss.png";
import line from "assets/images/line.svg";
// import modalImage1 from "assets/images/modal-ill.png";
import google from "assets/images/googlestore.png";
import apple from "assets/images/appstore.png";
import right from "assets/images/yellow-right.svg";
import { useMedia } from "react-use";
import Box from "ui-box/dist/src/box";
import { isIOS, isMacOs, isMobile, isAndroid } from "react-device-detect";
import {
  gaEventOpenAppStore,
  gaModalForm,
  gaGetUpdates,
  gaClosedNewsletterModal
} from "utils/analytics";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("You must enter a valid email")
    .required("Email is required"),
  firstName: yup.string().required("First Name is required")
});

const defaultValues = {
  email: "",
  firstName: ""
};

export const NewsLetterModal = ({ isOpen, closeModal, emailInputted }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedTab, setSelectedTab] = useState("");
  const [clickedId, setClickedId] = useState("");
  // const [form, setForm] = useState(INTIAL_FORM);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["nl"]);
  const [error, setError] = useState("");
  const { location } = useHistory();
  const currentLocation = useLocation();
  const values = queryString.parse(location.search, { parseBooleans: true });
  const isWide = useMedia("(max-width: 758px)");

  const { register, handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onChange"
  });

  const { isValid, isDirty, errors } = formState;

  const sendgridUrl = "https://api.sendgrid.com/v3/marketing/contacts";
  const apiKey = process.env.REACT_APP_SENDGRID_API_KEY;

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const subscribe = async ({ data }) => {
    try {
      setLoading(true);
      setError("");
      const response = await axios.put(
        sendgridUrl,

        {
          list_ids: ["cd3f71b0-0b50-4ab4-8264-ace00419d186"],
          contacts: [
            {
              email: data.email || emailInputted,
              first_name: capitalizeFirstLetter(data.firstName)
              // custom_fields: {
              //   e1_T: name
              // }
            }
          ]
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`
          }
        }
      );

      if (
        response.status === 200 ||
        response.status === 201 ||
        response.status === 202
      ) {
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setError(
        error?.response?.data?.title || "An error occurred please try again"
      );
    }
  };

  const tabTitle = [
    { id: "14db6", name: "Stress & Anxiety Management" },
    { id: "cd3vb", name: "Weight Management" },
    { id: "cv3ha", name: "Nutrition & Vitality" },
    { id: "cfw35", name: "Manage Substance Addiction" },
    { id: "q5g77", name: "Manage Prolonged Condition" },
    { id: "d5g45", name: "Maintain Health & Wellness" }
  ];

  const handleClick = () => {
    nextStep();
    gaGetUpdates("Click Get health updates");
  };

  const onSubmit = (e) => {
    setCookie("acceeptedNL", true, {
      path: "/"
    });
    subscribe({ data: e });
    gaModalForm("Subscribe for health updates");
  };

  const openPlaystore = () => {
    gaEventOpenAppStore("Open Google Playstore");
  };

  const openAppstore = () => {
    gaEventOpenAppStore("Open App Store");
  };

  const handleDownload = () => {
    if (isIOS || isMacOs) {
      openAppstore();
    } else {
      openPlaystore();
    }
  };

  const renderDownloadButtons = () => {
    if (isMobile) {
      if (isAndroid) {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.iddera.mobile.idderaAndroid"
          >
            <img src={google} className="nl__badges-image" />
          </a>
        );
      } else if (isIOS || isMacOs) {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/ng/app/iddera/id6450647702"
          >
            {" "}
            <img src={apple} className="nl__badges-image" />
          </a>
        );
      }
    }
    // If not mobile or specific platform, render both buttons
    return (
      <>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/ng/app/iddera/id6450647702"
        >
          {" "}
          <img src={apple} className="nl__badges-image" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.iddera.mobile.idderaAndroid"
        >
          <img src={google} className="nl__badges-image" />
        </a>
      </>
    );
  };

  const renderDisplay = () => {
    switch (currentStep) {
      case 1:
        return (
          <React.Fragment>
            <h1 className="nl__firsttext">
              Doctors you can <span>Nook™</span> with.
            </h1>
            <h1 className="nl__secondtext">
              Health conversations you want to have.
              <br />
              <span>Without prejudice or judgement.</span>
            </h1>
            <div className="nl__pattern">
              <img src={modalPattern} />
            </div>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position="relative"
              width="100%"
            >
              <img src={modalImage1} className="nl__image" />
              <img src={line} className="nl__line" />
            </Box>
            <Box
              display="flex"
              width="100%"
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              position={!isWide ? "absolute" : "unset"}
              bottom={!isWide ? "0.6rem" : "1rem"}
              left="2%"
            >
              <p className="nl__thirdtext">Get started for free</p>
              <Box display="flex" alignItems="center" gap="1rem">
                {" "}
                {renderDownloadButtons()}
              </Box>
              {isWide && (
                <div className="nl__button-mobile" onClick={handleClick}>
                  <p>
                    Get health updates <img src={right} />
                  </p>
                </div>
              )}
            </Box>
            {!isWide && (
              <div className="nl__action_button" onClick={handleClick}>
                <p>
                  Get health updates <img src={right} />
                </p>
              </div>
            )}
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <div className="nl__card-tab-selected">Live without limits!</div>
            <h1 className="nl__card-headtext">
              Join a community of overachievers and reach your wellness goals
              the fun way.
            </h1>
            <p className="nl__card-subtext">
              Get free tips and health information to make the right lifestyle
              choices to achieve your wellness goals.
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="nl__name-group">
                <input
                  className={
                    errors.firstName
                      ? "nl__card-input-red nl__name"
                      : "nl__card-input nl__name"
                  }
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  {...register("firstName")}
                  required
                />
              </div>
              <div className="nl__card__input-group">
                <input
                  className={
                    errors.email ? "nl__card-input-red" : "nl__card-input"
                  }
                  type="email"
                  name="email"
                  placeholder="Enter your email address"
                  {...register("email")}
                  required
                />
                {!isWide && (
                  <div className="nl__card-emailbutton">
                    <Button
                      variant="cornflower-blue"
                      id="emailbutton"
                      label="Subscribe"
                      type="submit"
                      disabled={!isDirty || !isValid}
                      onClick={handleSubmit}
                    />
                  </div>
                )}
              </div>
              {error && <p className="nl__error-msg">*{error}</p>}

              {isWide && (
                <Box marginTop="1rem">
                  <Button
                    variant="cornflower-blue"
                    id="emailbutton"
                    label="Subscribe"
                    type="submit"
                    disabled={!isDirty || !isValid}
                    onClick={handleSubmit}
                  />
                </Box>
              )}
            </form>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <div className="nl__card-tab-selected">{selectedTab.name}</div>
            <h1 className="nl__card-headtext">Awesome! You are subscribed.</h1>
            <div className="nl__close-button">
              <Button
                variant="cornflower-blue"
                id="emailbutton"
                label="Close"
                type="submit"
                onClick={closeModal}
              />
            </div>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    !values.mobile &&
    currentLocation.pathname !== "/verve" && (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className="nl__card-content"
        contentLabel="Menu"
        overlayClassName={`ap__dp-overlay`}
        appElement={document.querySelector("#root")}
      >
        <button
          className="nl-close-btn"
          aria-label="Close newsletter modal"
          onClick={() => {
            closeModal();
            gaClosedNewsletterModal("Close newsletter modal");
          }}
        >
          <WrappedIcon id="close" width={35} height={35} />
        </button>
        <div className={currentStep === 2 && "nl__card-container"}>
          {currentStep === 2 && (
            <div className="nl__card-left">
              <div className="nl__card-image"></div>
            </div>
          )}
          <div
            className={
              currentStep === 2 ? "nl__card-right-stp2" : "nl__card-right"
            }
          >
            {renderDisplay()}
          </div>
        </div>
      </Modal>
    )
  );
};
